<template>
  <svg
    width="8"
    height="12"
    viewBox="0 0 8 12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.41016 1.41L2.83016 6L7.41016 10.59L6.00016 12L0.00015614 6L6.00016 -6.16331e-08L7.41016 1.41Z"
    />
  </svg>
</template>
